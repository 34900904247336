import React from "react";
import Template from "../components/Template/Template";
import Section from "../components/Section/Section";
import Hero from "../components/Hero/Hero";
import hero from "./assets/svg/locations-hero.svg";
import ActionTiles from "../components/ActionTiles/ActionTiles";

export default ({ location, pageContext: { locations } }) => {
  const seo = { title: "BSM - Our Locations" };
  return (
    <Template seo={seo} location={location}>
      <Section className={"bg-bsm-grey-6 top-fill"}>
        <Hero
          svg={hero}
          smallText="BSM"
          largeText="Locations"
          page="locations-archive"
        />
      </Section>
      <Section className={"bg-bsm-primary-white blog-archive mt-5 pt-5"}>
        {locations.length ? (
          <ActionTiles
            tiles={locations.sort((a, b) => (a.text < b.text ? -1 : 1))}
            total={0}
            randomize={false}
            columns={2}
          />
        ) : (
          <div className="mt-5 pt-3 mb-4">No locations found!</div>
        )}
      </Section>
    </Template>
  );
};
